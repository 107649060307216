import React from "react"
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import Seo from "../components/SEO";
import Layout from "../components/Layout";

const StyledWrapper = styled.div`
    position: relative; 
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
`;

const StyledImage = styled(Img)`
    position: relative; 
    height: 100vh;
    width: 100%;
`;

const LogosWrapper = styled.div`
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
    
  ${({theme}) => theme.mq.desktop}  {
    flex-direction: row;
  }
`;

const StyledLink = styled.a`
  padding: 20px;
  width: 250px;
  height: 250px;
  background-color: white;
  margin: 1em;
  opacity: 0.9;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  
   ${({theme}) => theme.mq.desktop}  {
      width: 400px;
      height: 400px;
  }
  
    
  :visited {
    color: ${({theme}) => theme.black};
  }
  
`;

const StyledLogoFlorystyka = styled(Img)`
  width: 100%;
  height: 100%;
`;

const StyledLogoGreener = styled(Img)`
  width: 200px;
  height: auto;
  
  ${({theme}) => theme.mq.desktop}  {
    width: 350px;
  }
`;


const IndexPage = () => {

  const data = useStaticQuery(graphql`
      query IntroImages {
          background_image: file(relativePath: { eq: "intro_background.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 2000, quality: 80) {
                      ...GatsbyImageSharpFluid_noBase64
                  }
              }
          },
          logo_florystyka: file(relativePath: { eq: "logo_lawendowy_kacik.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid_noBase64
                  }
              }
          },
          logo_greener: file(relativePath: { eq: "logo_greener.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid_noBase64
                  }
              }
          }
      }
      
  `);

  return (
    <Layout>
      <Seo title="Greener - Architektura Zieleni - Poznań Gniezno" />
      <StyledWrapper>
        <StyledImage fluid={data.background_image.childImageSharp.fluid} />
        <LogosWrapper>
          <StyledLink href="https://www.greener.greener-ak.pl/">
            <StyledLogoGreener fluid={data.logo_greener.childImageSharp.fluid} alt="Greener" />
          </StyledLink>
          <StyledLink href="https://www.pracownia-florystyczna.greener-ak.pl/">
            <StyledLogoFlorystyka fluid={data.logo_florystyka.childImageSharp.fluid} alt="Pracownia florystyczna" />
          </StyledLink>
        </LogosWrapper>
      </StyledWrapper>
    </Layout>
  );
}

export default IndexPage
